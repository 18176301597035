import * as React from "react";
import { MapControl } from "../components/mapControl";
import PageHeader from "../components/pageHeader";

import "./get-in-touch.scss";
import { Helmet } from "react-helmet";

const GetInTouch: React.FunctionComponent<{}> = () => {
  return (
    <>
      <Helmet title="Get in touch" />
      <div className="fixed-width get-in-touch">
        <PageHeader
          heading={`Design your business\r\nfor the decade of change`}
          subHeading="Contact"
          headingElement="h1"
        />
        <MapControl />
        <div className="contact-blocks">
          <div>
            <h2>Contact us</h2>
            <a href="mailto:hello@corkewallis.com">hello@corkewallis.com</a>
            <br />
          </div>
          <div>
            <h2>Find us</h2>
            80 Sandbrook Rd
            <br />
            London
            <br />
            N16 OSP
          </div>
          <div>
            <h2>Follow us</h2>
            <a
              href="https://www.instagram.com/corkewallis?igshid=OGQ5ZDc2ODk2ZA=="
              target="_blank"
            >
              Instagram
            </a>
            <br />
            <a
              href="https://www.linkedin.com/company/corke-wallis/?viewAsMember=true"
              target="_blank"
            >
              LinkedIn
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetInTouch;
