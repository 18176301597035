import { graphql, Link } from "gatsby";
import Lottie from "lottie-react";
import { RichText } from "prismic-reactjs";
import * as React from "react";
import PageHeader from "../components/pageHeader";
import Placeholder from "../components/placeholder";
import { BlogPostQuery } from "../generated/graphql";
import { parseISO, format } from "date-fns";

import "./blogPost.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { BlogSliceHandler } from "../components/blogSliceHandler";
import { Helmet } from "react-helmet";

interface IProps {
  data: BlogPostQuery;
  pageContext: {
    slug: string;
  };
}

const BlogPost: React.FunctionComponent<IProps> = ({ data, pageContext }) => {
  const pageData = data.blogPost?.data;
  const date = parseISO(pageData?.date);

  const bodyCopy = pageData?.body1.find((b) => b.slice_type === "body_copy")
    .primary.body.richText[0];

  console.log(bodyCopy.text);

  return (
    <>
      <Helmet title={pageData?.title}>
        <meta name="description" content={pageData?.long_title || undefined} />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "BlogPosting",
              "headline": "${pageData?.title}",
              "description": "${pageData?.long_title}",
              "articleBody": ${JSON.stringify(bodyCopy.text)},
              "image": "${pageData?.hero_image?.url}",
              "url": "https://www.corkewallis.com/blog/${pageContext.slug}",
              "author": {
                "@type": "Person",
                "name": "${pageData?.author}",
                "jobTitle": "${pageData?.author_title}"
              },
              "datePublished": "${format(date, "yyyy-MM-dd")}",
              "inLanguage": "en-GB"
            }
          `}
        </script>
      </Helmet>
      <div className="fixed-width blog-wrapper">
        <PageHeader heading={pageData?.long_title || ""} headingElement="h1" />
        <div className="stamp">
          {pageData?.author}
          <br />
          {pageData?.author_title}
          <br />
          {format(date, "@HH:mm, dd.MM.yyyy")}
        </div>
        <GatsbyImage
          image={getImage(pageData?.hero_image?.gatsbyImageData)!}
          alt={pageData?.hero_image?.alt || ""}
        />
        <div className="intro-copy">
          <RichText render={pageData?.intro_copy?.richText} />
        </div>
        <BlogSliceHandler
          slices={pageData?.body1}
          shareData={{
            title: pageData?.title || "",
            url: `https://www.corkewallis.com/insights/${data.blogPost?.uid}`,
          }}
        />
        {/* <div className="blog-post">
        <RichText render={pageData?.body?.richText} />
      </div> */}
      </div>
    </>
  );
};

export const query = graphql`
  query BlogPost($slug: String!) {
    blogPost: prismicBlogPost(uid: { eq: $slug }) {
      uid
      data {
        title
        long_title
        author
        author_title
        date
        hero_image {
          gatsbyImageData
          alt
          url
        }
        intro_copy {
          richText
        }
        body1 {
          ... on PrismicBlogPostDataBody1BodyCopy {
            id
            slice_type
            primary {
              header
              body {
                richText
              }
            }
          }
          ... on PrismicBlogPostDataBody1PullOutCopy {
            id
            slice_type
            primary {
              copy
              image {
                gatsbyImageData
                alt
              }
            }
          }
          ... on PrismicBlogPostDataBody1SingleImage {
            id
            slice_type
            primary {
              image {
                gatsbyImageData
                alt
              }
            }
          }
          ... on PrismicBlogPostDataBody1VimeoVideo {
            id
            slice_type
            primary {
              vimeo_video_id
            }
          }
        }
      }
    }
  }
`;

export default BlogPost;
