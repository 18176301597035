import { graphql, Link } from "gatsby";
import Lottie from "lottie-react";
import { RichText } from "prismic-reactjs";
import * as React from "react";
import { VimeoComponent } from "../components/sliceComponents/vimeoComponent";
import { SliceHandler } from "../components/sliceHandler";
import { ServicePageQuery } from "../generated/graphql";

import arrowRight from "../assets/images/arrow-right.svg";

import "./caseStudy.scss";
import { Helmet } from "react-helmet";

interface IProps {
  data: ServicePageQuery;
  pageContext: {
    slug: string;
    lottieData: ILottieData[];
  };
}

const CaseStudy: React.FunctionComponent<IProps> = ({ data, pageContext }) => {
  const pageData = data.caseStudy?.data;
  const headerLottie = pageContext.lottieData.find(
    (ld) => ld.id === data.caseStudy?.uid
  );

  const noExtras =
    pageData?.deliverables?.richText.length === 0 &&
    pageData?.metrics_testimonial?.richText.length === 0;
  return (
    <>
      <Helmet title={pageData?.title}>
        <meta
          name="description"
          content={pageData?.short_description || undefined}
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "CreativeWork",
              "name": "${pageData?.title}",
              "description": "${pageData?.short_description}",
              "image": "${pageData?.cover_image?.url}",
              "url": "https://www.corkewallis.com/case-studies/${pageContext.slug}",
              "author": {
                "@type": "Organization",
                "name": "Corke Wallis",
                "url": "https://www.corkewallis.com"
              }
            }
          `}
        </script>
      </Helmet>
      <div className="case-study fixed-width">
        <div className="page-header">
          <div className="breadcrumb">
            <Link to="/work">Work</Link>
            <div>/</div>
            <Link to={`/case-studies/${pageContext.slug}`}>
              {pageData?.title}
            </Link>
          </div>
          <h1>{pageData?.alternative_page_title || pageData?.title}</h1>
          {pageData?.introduction && (
            <p className="intro">{pageData.introduction}</p>
          )}
        </div>
        {pageData?.cover_image && pageData.cover_image.url && (
          <img
            className="cover-image"
            src={pageData?.cover_image?.url}
            alt={pageData?.cover_image?.alt}
          />
        )}
        {headerLottie && (
          <Lottie
            className="cover-animation"
            animationData={JSON.parse(headerLottie.data)}
          />
        )}
        {pageData?.cover_vimeo && (
          <VimeoComponent vimeoId={pageData.cover_vimeo} />
        )}
        <div className="content-block" data-extras={!noExtras}>
          <div>
            <RichText render={pageData?.content?.richText} />
          </div>
          {!noExtras && (
            <div className="right-block">
              <h2>Deliverables</h2>
              <RichText render={pageData?.deliverables?.richText} />
              <h2>Impact</h2>
              <RichText render={pageData?.metrics_testimonial?.richText} />
              {pageData?.business_shift?.length !== 0 && (
                <div className="business-shift">
                  <h2>Business shift</h2>
                  {pageData?.business_shift?.map((bs) => (
                    <div key={bs.left}>
                      <div>{bs.left}</div>
                      <div className="bs-center">{bs.center}</div>
                      <div>{bs.right}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="slices">
          <SliceHandler
            slices={pageData?.body as any}
            lottieData={pageContext.lottieData}
          />
        </div>
        <Link to="/work" className="action">
          <img src={arrowRight} alt="Arrow right" />
          View our work
        </Link>
      </div>
    </>
  );
};

export const query = graphql`
  query ServicePage($slug: String!) {
    caseStudy: prismicCaseStudy(uid: { eq: $slug }) {
      uid
      data {
        title
        alternative_page_title
        introduction
        cover_image {
          alt
          url
        }
        cover_vimeo
        subtitle
        short_description
        content {
          richText
        }
        deliverables {
          richText
        }
        metrics_testimonial {
          richText
        }
        business_shift {
          center
          left
          right
        }
        body {
          ... on PrismicCaseStudyDataBodyLottieAnimationJson {
            id
            slice_type
            primary {
              caption
            }
          }
          ... on PrismicCaseStudyDataBodyVimeoVideo {
            id
            primary {
              vimeo_video_id
              caption
            }
            slice_type
          }
          ... on PrismicCaseStudyDataBodySingleImage {
            id
            slice_type
            primary {
              caption
              image {
                url
                alt
                gatsbyImageData(width: 1160, imgixParams: { auto: "" })
              }
            }
          }
          ... on PrismicCaseStudyDataBodyImageCarousel {
            id
            slice_type
            primary {
              caption
            }
            items {
              image {
                url
                alt
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;

export default CaseStudy;
