import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";
import { HeaderQuery } from "../generated/graphql";

import IconInsta from "../assets/images/icon-insta.svg";
import IconYoutube from "../assets/images/icon-youtube.svg";
import IconLinkedIn from "../assets/images/icon-linkedin.svg";

import FooterLogo from "../assets/images/footer-small.gif";
// import HeaderLogo from "../assets/images/header.gif";

import "./shell.scss";
import { RichText } from "prismic-reactjs";

const Shell: React.FunctionComponent = ({ children }) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const data = useStaticQuery<HeaderQuery>(graphql`
    query Header {
      site {
        siteMetadata {
          siteName
        }
      }
      prismicSharedContent {
        data {
          footer_top_text {
            richText
          }
          footer_bottom_copy
          header_logo {
            url
            alt
          }
          footer_logo {
            url
            alt
          }
        }
      }
    }
  `);

  React.useEffect(() => {
    if (menuOpen) {
      document.querySelector("html")?.classList.add("block-scroll");
    } else {
      document.querySelector("html")?.classList.remove("block-scroll");
    }
  }, [menuOpen]);

  const siteName = data.site!.siteMetadata!.siteName;

  return (
    <>
      <Helmet
        defaultTitle={siteName || ""}
        titleTemplate={`%s — ${siteName}`}
        htmlAttributes={{ lang: "en" }}
      >
        <meta
          name="description"
          content="Build a brand and service for the decade of change. Stay relevant. Find new audiences. De-risk change."
        />
        <meta
          name="keywords"
          content="Brand and service design,Proposition prototyping,Insight and strategy,Brand,Service,Campaigns and collateral,Customer and stakeholder insight ,Market trends ,Competitor review ,Positioning ,Naming ,Visual identity ,Verbal identity and messaging ,Culture and employee experience ,Customer journey mapping ,User insight ,Design systems ,UX/UI design ,Launch and activation campaigns ,Organic and paid social media ,Marketing collateral ,Video and animation,decade of change,Build businesses,Pure Planet,Brompton Bikes,Generation Home,Vircru,Harwell Campus,Exo,Onlia,Opteran,Achmea,Amercian Express,BNP Paribas,The Hague convention bureau,New Scientist,Places for People,Stanhope,Zopa,Michael Wallis,Nicola Wilson,Pat Tierney,Matthew Curtis,Christopher Edwards,Carlos Wilson,Will Carley Smith,Steven Day,Knowledge economy,Net Zero,Electric Bikes,Electric Cars,Fintech,Insuretech,Real estate,AI,Artificial intelligence,Start Ups,Green Cities,Placemaking,Mortgages,Home Ownership,R&D,University tech spin–out"
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebSite",
              "name": "${siteName}"
            }
          `}
        </script>
      </Helmet>
      <header>
        <div className="fixed-width">
          <Link to="/" className="logo">
            <img
              src={data.prismicSharedContent?.data.header_logo?.url}
              alt="Corke Wallis Logo"
            />
            <button
              data-open={menuOpen}
              className="menu-button"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <div />
              <div />
              <div />
            </button>
          </Link>
          <nav data-open={menuOpen}>
            <Link onClick={() => setMenuOpen(false)} to="/work">
              Work
            </Link>
            <Link onClick={() => setMenuOpen(false)} to="/brand-and-service">
              Brand and service design
            </Link>
            <Link
              onClick={() => setMenuOpen(false)}
              to="/proposition-prototyping"
            >
              Proposition prototyping
            </Link>
            {/* <Link onClick={() => setMenuOpen(false)} to="/about-us">
              Us
            </Link> */}
            <Link onClick={() => setMenuOpen(false)} to="/blog">
              Blog
            </Link>
            {/* <Link to="/insights">Insights</Link> */}
            <a
              className="btn"
              href="mailto:hello@corkewallis.com"
              target="_blank"
            >
              Get in touch
            </a>
            {/* <Link
              onClick={() => setMenuOpen(false)}
              className="btn"
              to="/get-in-touch"
            >
              Get in touch
            </Link> */}
          </nav>
        </div>
      </header>
      <main>{children}</main>
      <footer>
        {/* <div className="footer-form">
          <div className="fixed-width">
            <RichText
              render={data.prismicSharedContent?.data.footer_top_text?.richText}
            />
            <form name="contact" method="POST" data-netlify="true">
              <div>
                <input type="email" />
                <button type="submit">Send</button>
                <input type="hidden" name="form-name" value="contact"></input>
              </div>
            </form>
          </div>
        </div> */}
        <div className="footer-bottom">
          <div className="fixed-width">
            {data.prismicSharedContent?.data.footer_logo?.url && (
              <img
                className="footer-logo"
                src={data.prismicSharedContent?.data.footer_logo?.url}
                alt={data.prismicSharedContent?.data.footer_logo?.alt}
              />
            )}
            <h2>{data.prismicSharedContent?.data.footer_bottom_copy}</h2>
            <nav>
              <Link to="/work">Work</Link>
              <Link to="/brand-and-service">Services</Link>
              <Link to="/proposition-prototyping">Principles</Link>
              {/* <Link to="/about-us">Us</Link> */}
              <a href="mailto:hello@corkewallis.com" target="_blank">
                Get in touch
              </a>
              {/* <Link to="/get-in-touch">Get in touch</Link> */}
            </nav>
          </div>
        </div>
        <div className="footer-socials">
          <div className="fixed-width">
            <div>
              <p className="footer-white">
                {`${siteName}  ® Company registered in England and Wales. Company registration number: 07186081`}
              </p>
            </div>
            <div className="socials-block">
              <a href="https://www.instagram.com/corkewallis?igshid=OGQ5ZDc2ODk2ZA==">
                <img src={IconInsta} alt="Instagram" />
              </a>
              <a href="https://www.linkedin.com/company/corke-wallis/?viewAsMember=true">
                <img src={IconLinkedIn} alt="LinkedIn" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Shell;
